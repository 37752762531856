define('gusto/js/app/gusto.popover',["jquery"], function ($) {
    "use strict";

    var arrowSize = 5;

    function getPopoverElement($el, id) {
        var placement = $el.data('placement');
        var content = $el.data('content');
        return $('<div id=' + 'gr-popover' + id + ' class="popover popover-' + placement + '"><div class="popover__arrow"></div>' + content + '</div>');
    }

    function addEventListeners($el) {
        var trigger = $el.data('trigger');
        var placement = $el.data('placement');
        switch (trigger) {
            case 'hover':
                $el.hover(
                    function () {
                        var popoverId = $el.data('popover-id');
                        var $popover = $('#' + popoverId);
                        var position = $el.offset();
                        switch (placement) {
                            case 'top':
                                position.left += ($el.outerWidth() / 2) - ($popover.outerWidth() / 2);
                                position.top -= $el.outerHeight(true) + arrowSize;
                                $popover.css(position);
                                break;
                            case 'right':
                                position.left += $el.outerWidth() + arrowSize;
                                $popover.css(position);
                                break;
                            case 'bottom':
                                position.left += ($el.outerWidth() / 2) - ($popover.outerWidth() / 2);
                                position.top += $el.outerHeight(true) + arrowSize;
                                $popover.css(position);
                                break;
                            case 'left':
                                position.left -= $popover.outerWidth() + arrowSize;
                                $popover.css(position);
                                break;
                            default:
                                break;
                        }

                        $popover.addClass('show')
                    },
                    function () {
                        var popoverId = $el.data('popover-id');
                        $('#' + popoverId).removeClass('show');
                    });
                break;
            default:
                throw new Error('trigger: ' + trigger + ' not implemented');
        }
    }

    var popover = {
        $popoverElements: [],
        init: function () {
            var $popovers = $('[data-toggle="popover"]');
            $popovers.each(function (index, el) {
                var $el = $(el);
                $el.data('popover-id', 'gr-popover' + index);
                var $popoverEl = getPopoverElement($el, index);
                this.$popoverElements.push($popoverEl);
                addEventListeners($el);
            }.bind(this));

            $('body').append(this.$popoverElements);
        }
    };

    popover.init();
    return popover;
});

